<template>
    <div class="DeviceInfoList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="keepLogFrom" ref="keepLogFrom">
                    <el-form-item label="仪器编号">
                        <el-input v-model="keepLogFrom.deviceNum" placeholder="请输入仪器编号查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器名称">
                        <el-select placeholder="请选择仪器名称分类" v-model="keepLogFrom.deviceNameCode">
                            <el-option v-for="(item, index) in deviceNameList" :key="index" :label="item.realName"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="保养日期">
                        <el-date-picker v-model="keepLogFrom.keepDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择保养日期" >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceKeepLogList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center"> </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceType" label="仪器型号" align="center">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center">
                </el-table-column>
                <el-table-column prop="isGuiseGood_text" label="外观良好" align="center" width="80">
                </el-table-column>
                <el-table-column prop="isPzGood_text" label="配置完整" align="center" width="80" >
                </el-table-column>
                <el-table-column prop="isTdzjNormal_text" label="通电自检" align="center" width="80">
                </el-table-column>
                <el-table-column prop="isNeedCharge_text" label="是否充电" align="center" width="80" >
                </el-table-column>
                <el-table-column prop="powerTime_text" label="开机通电时长" align="center" width="100" >
                </el-table-column>
                <el-table-column prop="deviceStatus_text" label="仪器状态" align="center" >
                </el-table-column>
                <el-table-column prop="keepPersonnel" label="保养人员" align="center"  width="80">
                </el-table-column>
                <el-table-column prop="keepDate" label="保养日期" align="center" width="100" >
                </el-table-column>

                <el-table-column label="操作" align="center" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="keepLogFrom.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="keepLogFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

        </el-card>
    </div>
</template>

<script>
import { serchDeviceKeepLog, powerTimeList, deviceStatusList} from '@/api/deviceKeepLog.js'
import { getDeviceKeeps } from '@/api/deviceKeep.js'


export default {
    data() {
        return {
            deviceNameList: [],
            deviceKeepLogList: [],
            deviceStatusList: deviceStatusList,
            powerTimeList: powerTimeList,
            keepLogFrom: {
                deviceNum: null,
                deviceNameCode: null,
                keepDate: null,
                pageNum: 1,
                pageSize: 10
            },
            total: 0,

        }
    },
    mounted() {

        this.loadDeviceKeepNames()
        this.serchDeviceKeepLog()
    },
    methods: {

        handleSizeChange(val) {
            this.keepLogFrom.pageSize = val;
            this.keepLogFrom.pageNum = 1;
            this.serchDeviceKeepLog();
        },
        handleCurrentChange(val) {
            this.keepLogFrom.pageNum = val;
            this.serchDeviceKeepLog();
        },
        async loadDeviceKeepNames() {
            await getDeviceKeeps().then(res => {
                if (res.code === '000000') {
                    this.deviceNameList = res.t
                }

            })
        },

        //查询
        serchDeviceKeepLog() {
            serchDeviceKeepLog(this.keepLogFrom).then(res => {
                if (res.code === '000000') {
                    this.deviceKeepLogList = res.t.list
                    this.total = res.t.total
                    let count = 1;
                    this.deviceKeepLogList.forEach((item) => {
                        item.seq = count++
                        item.isGuiseGood ===0 ? (item.isGuiseGood_text='是') : (item.isGuiseGood_text='否')
                        item.isPzGood ===0 ? (item.isPzGood_text='是') : (item.isPzGood_text='否')
                        item.isTdzjNormal ===0 ? (item.isTdzjNormal_text='正常') : (item.isTdzjNormal_text='不正常')
                        item.isNeedCharge ===0 ? (item.isNeedCharge_text='是') : (item.isNeedCharge_text='否')
                        let index = this.powerTimeList.findIndex(item1 => item1.code===item.powerTime)
                        if(index !== -1){
                            const { name } = this.powerTimeList[index]
                            item.powerTime_text = name
                        }
                        let index2 = this.deviceStatusList.findIndex(item2 => item2.code === item.deviceStatus)
                        if(index2 !== -1){
                            const { name } = this.deviceStatusList[index2]
                            item.deviceStatus_text = name
                        }
                    })
                }

            })

        },

        //修改
        handleEdit({ id }) {
            this.$router.push({
                name: 'keepLog-edit',
                params: {
                    id
                }
            })
        },

        //详情
        handleDetail({ id }) {
            this.$router.push({
                name: 'keepLog-detail',
                params: {
                    id,
                    type: 'keepLog'
                }
            })
        },

        //搜索
        handleSearch() {
            this.serchDeviceKeepLog(this.keepLogFrom);
        },
        //重置
        handleReset() {
            this.keepLogFrom = {};
            this.serchDeviceKeepLog(this.keepLogFrom);
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceInfoList {

    .el-form-item,
    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

    .delInfo {
        font-size: 16px;
        text-align: left;
        margin-bottom: 15px;
    }

}
</style>